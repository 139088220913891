<template>
<div class='container'>
404
</div>
</template>

<style scoped>
.container {
  font-size: 1.6rem;
  text-align: center;
  padding-top: 10rem;
}
</style>
