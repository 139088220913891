<template>
<div class="hi">
&#9785;
</div>
</template>

<style scoped>
.hi {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  font-weight: 200;
  height: 100vh;
  padding-bottom: 16rem;
  box-sizing: border-box;
}
</style>
